export const environment = {
    version: '1.2021.0323.1',
    production: false,
    ConsumerToken: 'c4p8GFRvoWjo+x1I4QZNZP2hpsoRrIvkj44xP5J0zj6eG7fYI1RgNMyXXxNmmWdkjyRjjs4VlWJFMA8OR6huFw==',
    AuthenticationUrl: 'https://fleethealth-api.staging.gpscockpit.net/api/',
    InventoryUrl: 'https://fleetinventory-api.staging.gpscockpit.net/api/',
    FleetOverviewUrl: 'https://fleetoverview-api.staging.gpscockpit.net/api/',
    Environment: 'staging',
    Debug: true,
    SSOEnabled: true,
    SSOAuthenticationUrl: 'https://sso.staging.gpscockpit.net',
    SSORedirectUrl: 'https://fleethealth.staging.gpscockpit.net/#/Auth-callback?',
    SSOPostLogoutRedirectUrl: 'https://fleethealth.staging.gpscockpit.net/#/Endsession-callback',
    SSOFacebookID: '570992783334044',
    FORedirectUrl: 'https://fleetoverview-foaaa-new.staging.gpscockpit.net/#/',
    ApplicationName: '360Locate - Fleet Health',
};
